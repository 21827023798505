import React from "react";
import { api } from "../../api";
import './style.css';
import * as Images from "../../themes/images";
import { instanceOf } from 'prop-types';
import HeaderMobile from "../../components/headerMobile"
import { withCookies, Cookies } from 'react-cookie';
import ReactPixel from 'react-facebook-pixel';
class BundleSuccess extends React.Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        const { cookies } = props;
        this.state = {
           sentEmail: false
        }
        this.sendBundleEmail = this.sendBundleEmail.bind(this);
        this.goToConcierge = this.goToConcierge.bind(this);
    }
    
    async sendBundleEmail(){
        const queryParams = new URLSearchParams(window.location.search);
        const session_id = queryParams.get('session_id');
        const bundleid = queryParams.get('bundleid');
        const carRego = queryParams.get('carRego');
        const { cookies } = this.props;
        let data = {
            session_id: session_id
        };

        let res = await api(`bundle/purchase/${bundleid}/send?carRego=${carRego}`, 'POST', data);

        if(res.status == 200){
            if(res.data.success){
                this.setState({
                    sentEmail: true
                })
                cookies.set("sentEmail", true, { path: '/' , maxAge: 86400  });
            }
        }
    }

    goToConcierge(){
        const { cookies } = this.props;
        cookies.remove("sentEmail");
        window.location.href="https://conciergecarwash.co.nz";
    }

    componentDidMount(){
        document.title = 'Purchase Bundle Success - Concierge Car Wash';
        window.addEventListener("beforeunload", function (e) {
            cookies.remove("sentEmail");
        });
        const { cookies } = this.props;
        let hasSentEmail = cookies.get("sentEmail") ? cookies.get("sentEmail")  : false;
        this.setState({
            sentEmail: hasSentEmail,
        });

        if(hasSentEmail == false){
            this.sendBundleEmail();
        }
        ReactPixel.pageView();
    }

    render(){
        return(
            <div className="bookingSuccessPage">
                {window.innerWidth < 767 && <HeaderMobile/>}
                <div className="bookingBanner">
                    <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
                </div>
                <div className="successContent">
                    <img src={Images.blueTick} className="successTick" alt="success tick" />
                    <h1>{ "You successfully purchased the bundle"}</h1>
                    {
                        this.state.sentEmail !== false && (
                            <div>
                                <h3 className="bookingNumber">Bundle Email was sent successfully.</h3>
                            </div>
                        )
                    }
                    <div className="successButtonSection">
                        <button className="lightBlueButton" onClick={this.goToConcierge}> Continue </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withCookies(BundleSuccess);