import React from "react";
import { api, googleEvent } from "../../api";
import "./resumebooking.css";
import * as Images from "../../themes/images";
import { instanceOf } from "prop-types";
import HeaderMobile from "../../components/headerMobile";
import { withCookies, Cookies } from "react-cookie";
import Loading from "../../components/Loading";
import ReactPixel from "react-facebook-pixel";

class ResumeBookingSuccess extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      bookingNumber: false,
      error: false,
      errorMessage: "",
      loading: true,
    };
    this.getResumeBooking = this.getResumeBooking.bind(this);
  }

  async getResumeBooking() {
    const { cookies } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    const bookingKey = queryParams.get("bookingKey");

    console.log("bookingKey: ", bookingKey);

    let data = {
      bookingKey: bookingKey,
      status: "submitted"
    };
    if (bookingKey) {
      let res = await api("booking/resume", "POST", data);
      if (res.status == 200) {
        if (res.data.success) {
        //   this.props.fbEvent("Purchase", {
        //     currency: "AUD",
        //     value: Number(res.data.booking.cart.sessionData.estimatedTotal),
        //   });
        //   googleEvent(this.props.ReactGA, this.props.GTM, "booking_confirmed", {
        //     bookingNumber: res.data.bookingNumber,
        //   });
          this.setState({
            bookingNumber: res.data.bookingNumber,
            loading: false,
          });

          cookies.set("bookingNumber", res.data.bookingNumber, {
            path: "/",
            maxAge: 86400,
          });

          cookies.remove("cartId", { path: "/", maxAge: 86400 });
          cookies.remove("confirmStep", { path: "/", maxAge: 86400 });
          cookies.remove("extrasStep", { path: "/", maxAge: 86400 });
          cookies.remove("detailsStep", { path: "/", maxAge: 86400 });
          cookies.remove("serviceStep", { path: "/", maxAge: 86400 });
        } else {
          this.setState({
            error: true,
            errorMessage: res.data.message,
            loading: false,
          });
        }
      }
    } else {
      this.setState({
        error: true,
        errorMessage: "You can only access this page if you have confirmed a bookking.",
        loading: false,
      });
    }
  }

  setupBeforeUnloadListener = () => {
    const parentState = this;
    window.addEventListener("beforeunload", (ev) => {
      if (parentState.state.loading) {
        ev.preventDefault();
        return (ev.returnValue =
          "Are you sure you want to close? Your booking has not been processed yet and you may forfeit your $2 booking fee.");
      } else {
        return;
      }
    });
  };

  componentDidMount() {
    document.title = "Booking Success - Concierge Car Wash";
    const { cookies } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    ReactPixel.pageView();

    this.getResumeBooking();

    this.setState({
      bookingNumber: cookies.get("bookingNumber")
        ? cookies.get("bookingNumber")
        : false,
    });

    this.setupBeforeUnloadListener();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="bookingSuccessPage">
          {window.innerWidth < 767 && <HeaderMobile />}
          <div className="bookingBanner">
            <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
          </div>
          <div className="watingMessage">
            <h1>
              We are processing your booking, please do not leave this page
              until you recieve your booking reference number.
            </h1>
          </div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="bookingSuccessPage">
        {window.innerWidth < 767 && <HeaderMobile />}
        <div className="bookingBanner">
          <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
        </div>
        {this.state.error ? (
          <div className="failContent">
            <h1>{this.state.errorMessage}</h1>
            <div className="backToWebsite">
              <a
                className="lightBlueButton"
                href={"https://conciergecarwash.co.nz"}
              >
                {" "}
                Continue{" "}
              </a>
            </div>
          </div>
        ) : (
          <div className="successContent">
            <img
              src={Images.blueTick}
              className="successTick"
              alt="success tick"
            />
            <h1>
                You successfully confirmed your booking.
            </h1>
            {this.state.bookingNumber !== false && (
              <>
                <h3 className="bookingNumber">
                  Booking # {this.state.bookingNumber}
                </h3>
                <p className="note">
                  <b>Please Note</b>
                </p>
                <p className="note">
                  <b>Pricing:</b> The total price is an estimate, and an exact
                  quote will be given on the day. Prices vary depending on the
                  state, size and condition of your vehicle.
                </p>
                <p className="note">
                  <b>Time:</b> We will do our best to facilitate your
                  reservation time; however, at times, delays can occur. The
                  team will inform you of the estimated completion time.
                </p>
              </>
            )}
            <div className="backToWebsite">
              <a
                className="lightBlueButton"
                href={"https://conciergecarwash.co.nz"}
              >
                {" "}
                Continue{" "}
              </a>
            </div>
          </div>
        )}
        <footer class="successFoooter">
          <img src="https://hustleoutbound.s3.ap-southeast-2.amazonaws.com/637b47ec89db6c2ec519888a_ccwCleanCar.jpeg " />
        </footer>
      </div>
    );
  }
}

export default withCookies(ResumeBookingSuccess);
