import React from "react";
import { api, googleEvent } from "../../api";
import * as Images from "../../themes/images";
import "./free.css";
import HeaderMobile from "../../components/headerMobile";
import Loading from "../../components/Loading";
import { withAuth0 } from "@auth0/auth0-react";
import { CardOption } from "../../components";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import ReCAPTCHA from "react-google-recaptcha";

class Prepaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      prepaidCards: [],
      carTypeOptions: [
        {
          name: "Sedan",
          isSelected: false,
          cardId: 2400,
        },
        {
          name: "Wagon / SUV",
          isSelected: false,
          cardId: 2415,
        },
        {
          name: "4WD / 7 Seater",
          isSelected: false,
          cardId: 2416,
        },
      ],
      showPurchase: false,
      userDetails: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        rego: "",
      },
      auth0Int: null,
      isUser: false,
      errorField: {},
      card: {},
      promotionOver: false,
      showSuccess: false,
    };
    this.updateRegoVal = this.updateRegoVal.bind(this);
    this.handleBookingInputs = this.handleBookingInputs.bind(this);
    this.validate = this.validate.bind(this);
    this.createFreeExpressWash = this.createFreeExpressWash.bind(this);
    this.changeTypeSelected = this.changeTypeSelected.bind(this);
    this.freeExpressWashCount = this.freeExpressWashCount.bind(this);
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.freeExpressWashCount();
    // ReactPixel.pageView();
  }

  validate(values) {
    const errors = {};
    // const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!values.hasOwnProperty("firstName") || !values.firstName) {
      errors.firstName = "This field is required";
    }
    if (!values.hasOwnProperty("lastName") || !values.lastName) {
      errors.lastName = "This field is required";
    }
    if (!values.hasOwnProperty("email") || !values.email) {
      errors.email = "This field is required";
    }
    if (!values.hasOwnProperty("phone") || !values.phone) {
      errors.phone = "This field is required";
    }
    if (!values.hasOwnProperty("rego") || !values.rego) {
      errors.rego = "This field is required";
    }

    var carTypeSelected = false;
    for (let i = 0; i < this.state.carTypeOptions.length; i++) {
      const element = this.state.carTypeOptions[i];
      if (element.isSelected) {
        carTypeSelected = true;
      }
    }

    if (!carTypeSelected) {
      errors.vehcileType = "This field is required";
    }

    this.setState({
      errorField: errors,
    });

    return errors;
  }

  handleBookingInputs(val) {
    val = val.target;
    this.setState((prevState) => {
      let userDetails = Object.assign({}, prevState.userDetails); // creating copy of state variable jasper
      userDetails[val.name] = val.value; // update the name property, assign a new value
      return { userDetails };
    });
  }

  updateRegoVal(e) {
    this.setState({
      userRego: e.target.value,
    });
  }

  changeTypeSelected(value) {
    this.setState((prevState) => ({
      carTypeOptions: prevState.carTypeOptions.map((obj) =>
        obj.name === value
          ? Object.assign(obj, { isSelected: true })
          : Object.assign(obj, { isSelected: false })
      ),
    }));
  }

  async freeExpressWashCount() {
    try {
      const resp = await api(`prepaid/freeexpresswash`, "GET", null);
      console.log(resp);
      if (resp.status == 200) {
        if (resp.data.count == 500) {
          this.setState({
            promotionOver: true,
            isLoading: false,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      this.setState({
        promotionOver: true,
        isLoading: false,
      });
    }
  }

  async createFreeExpressWash() {
    let errors = this.validate(this.state.userDetails);
    console.log(errors);

    const recaptchaValue = this.recaptchaRef.current.getValue();
    let recaptchGood = false;
    try {
      const response = await api("verify-recaptcha", "POST", {
        recaptchaValue,
      });
      const data = await response.data;

      console.log("reCAPTCHA verification result:", data);
      if (data.success) {
        recaptchGood = true;
      } else {
        recaptchGood = false;
      }
    } catch (error) {
      console.error("Error verifying reCAPTCHA:", error);
    }

    if (recaptchGood) {
      if (Object.keys(errors).length === 0) {
        this.setState({
          isLoading: true,
        });

        let cardId = 0;
        for (let i = 0; i < this.state.carTypeOptions.length; i++) {
          const element = this.state.carTypeOptions[i];
          if (element.isSelected) {
            cardId = element.cardId;
            i = this.state.carTypeOptions.length;
          }
        }

        let data = {
          firstName: `${this.state.userDetails.firstName}`,
          lastName: `${this.state.userDetails.lastName}`,
          rego: this.state.userDetails.rego,
          email: this.state.userDetails.email,
          mobile: this.state.userDetails.phone,
          cardId: cardId,
        };

        try {
          const resp = await api(`prepaid/freeexpresswash`, "POST", data);
          // console.log(resp);
          if (resp.status == 200) {
            this.setState({
              card: resp.data.card,
              showSuccess: true,
              isLoading: false,
            });
            googleEvent(
              this.props.ReactGA,
              this.props.GTM,
              "user_claimed_free_express_wash",
              { user: data }
            );
          }
          this.setState({
            isLoading: false,
          });
        } catch (error) {
          console.log(error);
          alert(error.response.data.error);
          this.setState({
            isLoading: false,
          });
        }
      }
    } else {
      alert("Failed Google Recaptcha, Please try again.");
    }
  }

  render() {
    let parentThis = this;
    return (
      <div className="prepaidCardPage">
        <Helmet>
          <title>Free Express Wash Giveaway | Concierge Car Wash</title>
          <link
            rel="canonical"
            href="https://booking.conciergecarwash.co.nz/free-express-wash-giveaway"
          />
          <meta name="robots" content="noindex,nofollow" />
          <meta
            name="description"
            content="Enter your details to redeem a free express wash prepaid card."
          />
        </Helmet>
        {window.innerWidth < 767 && <HeaderMobile />}
        <div className="prepaidBanner"></div>
        <div className="bookingHeader">
          <div className="logoSection">
            <a href="https://conciergecarwash.co.nz">
              <img src={Images.ccwLogoLight} className="ccwLogo" alt="Logo" />
            </a>
          </div>
          <div className="titleSection">
            <h1>FREE EXPRESS WASH GIVEAWAY</h1>
          </div>
          <div className="closeButtonSection">
            <a href="https://booking.conciergecarwash.co.nz">
              <img className="closeIcon" src={Images.closeIcon} alt="close" />
            </a>
          </div>
        </div>
        <div className="prepaidContainer">
          <div className={"inputsSection displayCenter"}>
            <h2>
              The Promotion has now ended. Keep an eye out for the next one!
            </h2>
          </div>
        </div>
        {/* {parentThis.state.isLoading ? (
          <Loading />
        ) : parentThis.state.promotionOver ? (
          
        ) : parentThis.state.showSuccess == false ? (
          <div className="prepaidContainer">
            <div className={"inputsSection displayCenter"}>
              <h2>Please enter your details</h2>
              <div className="prepaidUserDetails">
                <div className="flexRow">
                  <div className="twoColumn">
                    <input
                      type={"text"}
                      name={"firstName"}
                      id={"bookingFirstName"}
                      placeholder={"First name"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.firstName
                      }
                    />
                    {parentThis.state.errorField.firstName ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.firstName}
                      </p>
                    ) : null}
                  </div>
                  <div className="twoColumn">
                    <input
                      type={"text"}
                      name={"lastName"}
                      id={"bookingLastName"}
                      placeholder={"Last name"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.lastName
                      }
                    />
                    {parentThis.state.errorField.lastName ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.lastName}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"text"}
                      name={"phone"}
                      id={"bookingPhone"}
                      placeholder={"Phone"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.phone
                      }
                    />
                    {parentThis.state.errorField.phone ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.phone}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"email"}
                      name={"email"}
                      id={"bookingEmail"}
                      placeholder={"Email address"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.email
                      }
                    />
                    {parentThis.state.errorField.email ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flexRow">
                  <div className="oneColumn">
                    <input
                      type={"text"}
                      name={"rego"}
                      id={"bookingRego"}
                      placeholder={"Car rego number"}
                      onChange={this.handleBookingInputs}
                      onBlur={this.handleBlurInputs}
                      value={
                        parentThis.state.userDetails &&
                        parentThis.state.userDetails.rego
                      }
                    />
                    {parentThis.state.errorField.rego ? (
                      <p className="errorMess">
                        {parentThis.state.errorField.rego}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="sizeContainer displayCenter">
                <h2>Select vehicle type</h2>
                <div className="carTypeOptions">
                  {this.state.carTypeOptions.map(function (item) {
                    return (
                      <CardOption
                        item={item}
                        select={(item) => parentThis.changeTypeSelected(item)}
                        key={item.name}
                      />
                    );
                  })}
                </div>
                {parentThis.state.errorField.vehcileType ? (
                  <p className="errorMess">
                    {parentThis.state.errorField.vehcileType}
                  </p>
                ) : null}
              </div>
              <div className="displayCenter noteSection">
                <p>
                  Note: Speak to your wash specialist to use this prepaid card
                  with another service. This prepaid card is only valid for 3
                  months.
                </p>
              </div>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={process.env.REACT_APP_GCPTA_SITE}
                onChange={this.handleRecaptchaChange}
              />
              <div className="selectedServiceSection displayCenter">
                <button
                  className="prepaidCardButtonPurchase"
                  onClick={() => parentThis.createFreeExpressWash()}
                >
                  Get Your Free Prepaid Card
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="prepaidContainer">
            <div className="prepaidCardCode">
              <h2>Your Free Prepaid Card Code</h2>
              <h3>{this.state.card.Card.CardCode[0]}</h3>
              <p>
                An Email has been sent to you with your free express wash card.
              </p>
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

export default withAuth0(Prepaid);
